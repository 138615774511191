export const subscriptionTypes = {
  free: 'free',
  arb: 'arb',
};

export const newsTypes = {
  free: 'free',
  latest: 'latest',
  actuals: 'actuals',
};

export const arbitrageTypes = {
  free: 'free',
  cexToDex: 'cex-dex',
  cexToCex: 'cex-cex',
  dexToDex: 'dex-dex',
};

//links

export const telegramLink = 'https://t.me/ownr777';
export const subscribeTelegramLink = 'https://t.me/ownr777'; //"https://t.me/OneWhaleNews"
export const mailLink = 'mailto:info@1whale.io';
