import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

export const useSocket = (onMessageReceived, onRequestData) => {
  const user = useSelector(state => state.auth.user);
  const autoRefresh = useSelector(state => state.arbitrage.autoRefresh);
  const token = useSelector(state => state.auth.token);
  const socketRef = useRef(null);

  useEffect(() => {
    if (!user) return;

    if (autoRefresh) {
      socketRef.current = new WebSocket('wss://1whale.io/ws');

      socketRef.current.addEventListener('open', () => {
        const subscribeMessage = {
          event: 'subscribe',
          kind: 'arb',
          id: user.id,
          token,
        };

        socketRef.current.send(JSON.stringify(subscribeMessage));
      });

      socketRef.current.addEventListener('message', event => {
        try {
          const responseData = JSON.parse(event.data);
          if (responseData.data) onMessageReceived(responseData.data);
          if (responseData.id === -1) window.location.reload();
        } catch (error) {}
      });

      socketRef.current.addEventListener('close', () => {});

      socketRef.current.addEventListener('error', event => {});
    } else {
      onRequestData();
    }

    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, [user, autoRefresh, token]);
};
