import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { arbitrageTypes, subscriptionTypes } from '../../../constants';
import { useSocket } from '../../../hooks/useSocket';
import {
  getArbitrage,
  requestArbitrage,
  setAutoRefresh,
} from '../../../redux/action/arbitrage';
import { changeUserData } from '../../../redux/action/auth';
import { arbitragePagePath } from '../../../router/path';
import DataLoader from '../../layout/DataLoader/DataLoader';
import styles from './ArbitragePageList.module.scss';
import ArbitragePageListItem from './ArbitragePageListItem/ArbitragePageListItem';

const filterType = (item, hash) => {
  const type = hash.slice(1);
  if (type === 'free') {
    return item.kind === arbitrageTypes.cexToCex;
  } else {
    return item.kind === type;
  }
};

function ArbitragePageList() {
  const { hash } = useLocation();
  const navigate = useNavigate();
  const user = useSelector(state => state.auth.user);
  const data = useSelector(state => state.arbitrage.data);
  const filters = useSelector(state => state.arbitrage.filters);
  const loading = useSelector(state => state.arbitrage.getLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setAutoRefresh());

    if (!hash) {
      navigate(`${arbitragePagePath}#free`);
    }
  }, []);

  useSocket(
    payload => {
      dispatch(getArbitrage(payload));
    },
    () => dispatch(requestArbitrage())
  );

  const onAddToBlackList = symbol => {
    dispatch(
      changeUserData(
        {
          kind: hash.slice(1),
          blacklist: [...filters.blacklist, symbol],
        },
        false,
        () => {
          dispatch(requestArbitrage());
        }
      )
    );
  };

  const onAddToHidden = item => {
    const newItem = {
      symbol: item.symbol,
      ex1: item.ex1,
      ex2: item.ex2,
    };
    const oldData = filters.hidden || [];

    dispatch(
      changeUserData(
        {
          kind: hash.slice(1),
          hidden: [...oldData, newItem],
        },
        false,
        () => {
          dispatch(requestArbitrage());
        }
      )
    );
  };

  const filteredData = data.filter(item => filterType(item, hash));

  return (
    <div className={styles['arbitrageList']}>
      {filteredData.length ? (
        <div className={styles['arbitrageList__container']}>
          {filteredData.map(item => (
            <ArbitragePageListItem
              key={item.id}
              onAddToBlackList={onAddToBlackList}
              onAddToHidden={onAddToHidden}
              isArb={user?.subscription.includes(subscriptionTypes.arb)}
              isCexToDex={hash.slice(1) === arbitrageTypes.cexToDex}
              isDexToDex={hash.slice(1) === arbitrageTypes.dexToDex}
              {...item}
            />
          ))}
        </div>
      ) : null}
      <DataLoader loading={loading} isEmpty={!filteredData.length} />
    </div>
  );
}

export default ArbitragePageList;
