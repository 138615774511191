import React from 'react';
import { errorImage } from '../../assets/images';
import styles from './ErrorPage.module.scss';
import { Scrollbar } from 'react-scrollbars-custom';

function ErrorPage() {
  return (
    <Scrollbar
      style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}
    >
      <div className={styles['error']}>
        <div className={styles['error__container']}>
          <img src={errorImage} alt='' className={styles['error__img']} />
          <h2 className={styles['error__title']}>Page Not Found</h2>
        </div>
      </div>
    </Scrollbar>
  );
}

export default ErrorPage;
