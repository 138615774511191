import { subscriptionTypes } from '../../constants';
import {
  getLSItem,
  removeLSItem,
  setLSItem,
} from '../../utils/functions/localStorage';
import { lsProps } from '../../utils/lsProps';
import {
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_LOADING_START,
  CHANGE_PASSWORD_SUCCESS,
  EDIT_USER_DATA_ERROR,
  EDIT_USER_DATA_LOADING_START,
  EDIT_USER_DATA_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_LOADING_START,
  FORGOT_PASSWORD_SUCCESS,
  GET_USER_ERROR,
  GET_USER_LOADING_START,
  GET_USER_SUCCESS,
  LOGIN_ERROR,
  LOGIN_LOADING_START,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_LOADING_START,
  RESET_PASSWORD_SUCCESS,
  SET_ARBITRAGE_FILTERS,
  SIGNUP_ERROR,
  SIGNUP_LOADING_START,
  SIGNUP_SUCCESS,
} from '../types';
import {
  authConfig,
  baseConfig,
  changePassUrl,
  checkIsSubscribedUrl,
  createFilterUrl,
  fetchRequest,
  forgotPasswordUrl,
  getUserUrl,
  resetPasswordUrl,
  setEmptyFieldsError,
  setError,
  siginUrl,
  signupUrl,
} from './fetchTools';

const authenticateUser =
  (url, formData, successType, setError, clb, config, forFilters, isAdmin) =>
  async dispatch => {
    const method = formData ? 'POST' : 'GET';
    const body = formData ? JSON.stringify(formData) : null;
    const res = await fetchRequest(url, method, body, config || baseConfig);

    const { token, user: User, status } = res;

    if (res.status === 'Fail') {
      throw new Error('Loading error');
    }

    if ((token && User && !status) || status !== 'Fail') {
      if (isAdmin && User.role !== 'admin') {
        throw { message: 'Ты не Админ', status: 400 };
      }
      const {
        minAmount,
        maxAmount,
        profit,
        hidden,
        blacklist,
        exchanges,
        hiddenTime,
        updateTime,
        blockchains,
        ...user
      } = User;

      const filters = {
        minAmount,
        maxAmount,
        profit,
        hidden: hidden || [],
        blacklist: blacklist || [],
        exchanges,
        hiddenTime,
        blockchains,
        updateTime,
      };
      if (!forFilters) {
        dispatch({
          type: successType,
          payload: { token, user: { ...user, id: user.uid } },
        });
        setLSItem(lsProps.token, token);
        setLSItem(lsProps.user, { ...user, id: user.uid });
      }
      dispatch({ type: SET_ARBITRAGE_FILTERS, payload: filters });
      setLSItem(lsProps.filters, filters);
      if (clb) clb();
    } else {
      dispatch(setError('Не авторизован'));
    }
  };

export const signup = (formData, clb) => async dispatch => {
  dispatch({ type: SIGNUP_LOADING_START });

  setEmptyFieldsError(formData);

  try {
    await dispatch(
      authenticateUser(signupUrl, formData, SIGNUP_SUCCESS, setSignupError, clb)
    );
  } catch (err) {
    dispatch(setSignupError(err));
  }
};

export const checkIsLoggedIn = () => dispatch => {
  dispatch({ type: GET_USER_LOADING_START });
  const token = getLSItem(lsProps.token);
  const user = getLSItem(lsProps.user, true);
  const filters = getLSItem(lsProps.filters, true);

  dispatch({
    type: GET_USER_SUCCESS,
    payload: { token, user: user !== null ? { ...user, id: user.uid } : null },
  });
  dispatch({ type: SET_ARBITRAGE_FILTERS, payload: filters });

  if (token) {
    dispatch(getUser());
  }
};

export const setSignupError = err => dispatch => {
  dispatch(setError(err, SIGNUP_ERROR));
};

export const login = (formData, clb, isAdmin) => async dispatch => {
  dispatch({ type: LOGIN_LOADING_START });
  try {
    setEmptyFieldsError(formData);
    await dispatch(
      authenticateUser(
        siginUrl,
        formData,
        LOGIN_SUCCESS,
        setLoginError,
        clb,
        undefined,
        undefined,
        isAdmin
      )
    );
  } catch (err) {
    dispatch(setLoginError(err));
  }
};

export const setLoginError = err => dispatch => {
  dispatch(setError(err, LOGIN_ERROR));
};

export const getUser = () => async dispatch => {
  dispatch({ type: GET_USER_LOADING_START });
  try {
    await dispatch(
      authenticateUser(
        getUserUrl,
        null,
        GET_USER_SUCCESS,
        setGetUserError,
        undefined,
        authConfig()
      )
    );
  } catch (err) {
    dispatch(logOut());
    dispatch(setLoginError(err));
  }
};

export const setGetUserError = err => dispatch => {
  dispatch(setError(err, GET_USER_ERROR));
};

export const forgotPassword = (formData, clb) => async dispatch => {
  dispatch({ type: FORGOT_PASSWORD_LOADING_START });
  try {
    setEmptyFieldsError(formData);

    const res = await fetchRequest(
      forgotPasswordUrl,
      'POST',
      JSON.stringify(formData),
      baseConfig
    );

    if (res.status !== 'OK') {
      throw { message: `Неверный Эл. адрес`, status: 400 };
    }
    dispatch({ type: FORGOT_PASSWORD_SUCCESS });
    clb();
  } catch (err) {
    dispatch(setForgotPasswordError(err));
  }
};

export const setForgotPasswordError = err => dispatch => {
  dispatch(setError(err, FORGOT_PASSWORD_ERROR));
};

export const resetPassword = (formData, clb) => async dispatch => {
  dispatch({ type: RESET_PASSWORD_LOADING_START });

  try {
    setEmptyFieldsError(formData);
    await dispatch(
      authenticateUser(
        resetPasswordUrl,
        {
          activationCode: Number(formData.activationCode),
          email: formData.email,
          password: formData.password,
        },
        RESET_PASSWORD_SUCCESS,
        setResetPasswordError,
        clb
      )
    );
  } catch (err) {
    dispatch(setResetPasswordError(err));
  }
};

export const setResetPasswordError = err => dispatch => {
  dispatch(setError(err, RESET_PASSWORD_ERROR));
};

export const logOut = clb => dispatch => {
  removeLSItem(lsProps.token);
  removeLSItem(lsProps.user);
  removeLSItem(lsProps.filters);
  removeLSItem(lsProps.pushendpoint);
  removeLSItem(lsProps.usePushNot);
  dispatch({ type: LOGOUT_USER });
  if (clb) clb();
};

export const checkIsSubscribed = clb => async (dispatch, getState) => {
  dispatch({ type: GET_USER_LOADING_START });
  try {
    const fetchData = await fetchRequest(checkIsSubscribedUrl);
    const user = getState().auth.user;
    const subscription = fetchData.IsSubscribed
      ? subscriptionTypes.arb
      : subscriptionTypes.free;
    const newData = { user: { ...user, id: user.uid }, subscription };
    dispatch({ type: GET_USER_SUCCESS, payload: { user: newData } });
    setLSItem(lsProps.user, newData);
    if (clb) clb();
  } catch (err) {
    dispatch({ type: GET_USER_ERROR });
  }
};

export const changePassword = (formData, clb) => async dispatch => {
  dispatch({ type: CHANGE_PASSWORD_LOADING_START });
  try {
    setEmptyFieldsError(formData);
    await fetchRequest(changePassUrl, 'POST', JSON.stringify(formData));
    dispatch({ type: CHANGE_PASSWORD_SUCCESS });
    clb();
  } catch (err) {
    dispatch({
      type: CHANGE_PASSWORD_ERROR,
      payload:
        err?.message === 'Unexpected end of JSON input'
          ? 'Неверный старый пароль'
          : err.message,
    });
  }
};

export const changeUserData =
  (formData, showEmptyFieldsError, clb) => async (dispatch, getState) => {
    if (showEmptyFieldsError) dispatch({ type: EDIT_USER_DATA_LOADING_START });
    const user = getState().auth.user;

    const reqData = {
      id: user.id,
      field: Object.keys(formData),
      value: Object.values(formData),
    };
    try {
      if (showEmptyFieldsError) setEmptyFieldsError(formData);
      dispatch(
        authenticateUser(
          createFilterUrl,
          reqData,
          EDIT_USER_DATA_SUCCESS,
          setEditUserDataError,
          clb,
          authConfig(),
          !showEmptyFieldsError
        )
      );
    } catch (err) {
      dispatch({ type: EDIT_USER_DATA_ERROR, payload: err });
    }
  };

export const setEditUserDataError = err => dispatch => {
  dispatch(setError(err, EDIT_USER_DATA_ERROR));
};

export const onUserSubscribe =
  (subscription, usePush, clb) => (dispatch, getState) => {
    const user = getState().auth.user;
    const payload = user.pushSubscription?.length
      ? [...user.pushSubscription, subscription]
      : [subscription];
    dispatch(
      changeUserData({ push_subscription: payload }, true, () => {
        setLSItem(lsProps.pushendpoint, subscription.endpoint);
        if (!usePush) {
          setLSItem(lsProps.usePushNot, true);
        }
        if (clb) clb();
      })
    );
  };
