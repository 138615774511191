import {
  GET_ARBITRAGE_ERROR,
  GET_ARBITRAGE_LOADING_START,
  GET_ARBITRAGE_SUCCESS,
  SET_ARBITRAGE_AUTO_REFRESH,
  SET_ARBITRAGE_FILTERS,
  TOGGLE_ARBITRAGE_AUTO_REFRESH,
} from '../types';

const initialState = {
  data: [],
  getLoading: false,
  getError: null,
  autoRefresh: false,
  filters: {
    minAmount: 0,
    maxAmount: 0,
    profit: 0,
    hidden: [],
    blacklist: [],
    exchanges: [],
    hiddenTime: 0,
    updateTime: 2,
    blockchains: [],
  },
};

export const arbitrageReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ARBITRAGE_SUCCESS: {
      return {
        ...state,
        data: payload,
        getLoading: false,
      };
    }
    case GET_ARBITRAGE_LOADING_START: {
      return {
        ...state,
        getLoading: true,
        getError: null,
      };
    }
    case GET_ARBITRAGE_ERROR: {
      return {
        ...state,
        getError: payload,
        getLoading: false,
      };
    }
    case SET_ARBITRAGE_AUTO_REFRESH: {
      return {
        ...state,
        autoRefresh: payload,
      };
    }
    case TOGGLE_ARBITRAGE_AUTO_REFRESH: {
      return {
        ...state,
        autoRefresh: !state.autoRefresh,
      };
    }
    case SET_ARBITRAGE_FILTERS: {
      return {
        ...state,
        filters: payload || state.filters,
      };
    }
    default:
      return state;
  }
};
