import React, { useMemo, useState } from 'react';
import {
  editEmailImage,
  editUsernameImage,
  passwordKeyImage,
} from '../../../assets/images';
import styles from './SettingsPageBlock.module.scss';
import SettingsForm from './SettingsForm/SettingsForm';
import { useDispatch, useSelector } from 'react-redux';
import { changePassword, changeUserData } from '../../../redux/action/auth';
import { getLSItem } from '../../../utils/functions/localStorage';
import { lsProps } from '../../../utils/lsProps';
import { getAnalytics } from 'firebase/analytics';
import { getMessaging, getToken } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';

import {
  baseUrl as apiBaseUrl,
  authConfig,
} from '../../../redux/action/fetchTools.js';

const changePassFields = [
  {
    icon: passwordKeyImage,
    placeholder: 'Старый пароль',
    key: 'oldPassword',
    type: 'password',
  },
  {
    icon: passwordKeyImage,
    placeholder: 'Новый пароль',
    key: 'newPassword',
    type: 'password',
  },
];

const changeUserFields = user => [
  {
    icon: editEmailImage,
    placeholder: 'Email',
    key: 'email',
    type: 'email',
    initialValue: user?.email || '',
  },
  {
    icon: editUsernameImage,
    placeholder: 'Имя пользователя',
    key: 'username',
    initialValue: user?.username || '',
  },
];

function SettingsPageBlock() {
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const changePasswordLoading = useSelector(
    state => state.auth.changePasswordLoading
  );
  const changePasswordError = useSelector(
    state => state.auth.changePasswordError
  );
  const editDataLoading = useSelector(state => state.auth.editDataLoading);
  const editDataError = useSelector(state => state.auth.editDataError);
  const [isNotificationsActive, setIsNotificationsActive] = useState(
    getLSItem(lsProps.usePushNot, true)
  );

  const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOM,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDING_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  };

  const toggleNotificationsActivity = () => {
    if (isNotificationsActive) {
      // Инициализируйте Firebase

      const app = initializeApp(firebaseConfig);
      const analytics = getAnalytics(app);
      const messaging = getMessaging(app);
      getToken(messaging, {
        vapidKey: process.env.REACT_APP_PUBLIC_VAPID_KEY,
      })
        .then(currentToken => {
          if (currentToken) {
            // Отправьте токен на сервер
            fetch(`${apiBaseUrl}/deleteToken`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ token: currentToken }),
              ...authConfig(),
            })
              .then(response => response.json())
              .then(data => {})
              .catch(error => {});
          } else {
          }
        })
        .catch(err => {});
      // }
      setIsNotificationsActive(false);
      // let usePush = getLSItem(lsProps.usePushNot, true)
      // subscribePush((sub) => dispatch(
      // onUserSubscribe(sub, usePush, () => setIsNotificationsActive(true)))
      // )

      // const pushendpoint = getLSItem(lsProps.pushendpoint, true)
      // const payload = user.push_subscription.filter(item => item.endpoint !== pushendpoint)
      // const onSuccess = () => {
      //     removeLSItem(lsProps.pushendpoint)
      //     setLSItem(lsProps.usePushNot, false)
      //     setIsNotificationsActive(false)
      // }

      // dispatch(
      //     changeUserData(
      //         { push_subscription: payload },
      //         false,
      //         onSuccess
      //     ))
    } else {
      // Инициализируйте Firebase

      const app = initializeApp(firebaseConfig);

      const messaging = getMessaging(app);
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          getToken(messaging, {
            vapidKey: process.env.REACT_APP_PUBLIC_VAPID_KEY,
          })
            .then(currentToken => {
              if (currentToken) {
                // Отправьте токен на сервер
                fetch(`${apiBaseUrl}/addToken`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({ token: currentToken }),
                  ...authConfig(),
                })
                  .then(response => response.json())
                  .then(data => {})
                  .catch(error => {});
              } else {
              }
            })
            .catch(err => {});
          // }
          setIsNotificationsActive(true);
          // let usePush = getLSItem(lsProps.usePushNot, true)
          // subscribePush((sub) => dispatch(
          // onUserSubscribe(sub, usePush, () => setIsNotificationsActive(true)))
          // )
        } else if (permission === 'denied') {
        }
      });
    }
  };

  const onChangePassword = (formData, clb) => {
    dispatch(changePassword({ ...formData, email: user.email }, clb));
  };

  const onEditUserData = (formData, clb) => {
    dispatch(changeUserData(formData, true, clb));
  };

  const memoizedChangeUserFields = useMemo(
    () => changeUserFields(user),
    [user]
  );

  return (
    <div className={styles['settingsBlock']}>
      <h5 className={`${styles['settingsBlock__routeTitle']} pageRouteTitle`}>
        Главная > Settings
      </h5>
      <div className={styles['settingsBlock__main']}>
        <div className={styles['settingsBlock__mainTopBlock']}>
          <div className={styles['settingsBlock__box']}>
            <h6 className={styles['settingsBlock__boxSubtitle']}>
              Смена данных
            </h6>
            <h4 className={styles['settingsBlock__boxTitle']}>Общие</h4>
            {user ? (
              <SettingsForm
                fields={memoizedChangeUserFields}
                onSubmit={onEditUserData}
                error={editDataError}
                loading={editDataLoading}
                submitSuccessText={'Данные обновлены.'}
              />
            ) : null}
          </div>
          <div className={styles['settingsBlock__box']}>
            <h6 className={styles['settingsBlock__boxSubtitle']}>
              Смена пароля
            </h6>
            <h4 className={styles['settingsBlock__boxTitle']}>Безопасность</h4>
            {user ? (
              <SettingsForm
                fields={changePassFields}
                onSubmit={onChangePassword}
                error={changePasswordError}
                submitSuccessText={'Пароль обновлен.'}
                loading={changePasswordLoading}
              />
            ) : null}
          </div>
        </div>
        <div className={styles['settingsBlock__box']}>
          <h6 className={styles['settingsBlock__boxSubtitle']}>
            Примечание: обязательно разрешайте уведомления, когда браузер
            запрашивает ваше разрешение.
          </h6>
          <h4 className={styles['settingsBlock__boxTitle']}>
            Push-уведомления браузера
          </h4>
          <div className={styles['settingsBlock__notificationBlock']}>
            <div>
              <h6 className={styles['settingsBlock__notificationBlockTitle']}>
                Арбитраж
              </h6>
              <p className={styles['settingsBlock__notificationBlockText']}>
                Получать уведомления браузера о новых возможностях арбитража.
              </p>
            </div>
            <button
              onClick={toggleNotificationsActivity}
              className={`${styles['settingsBlock__notificationBlockBtn']} ${
                isNotificationsActive
                  ? styles['settingsBlock__notificationBlockBtn_active']
                  : ''
              }`}
            ></button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SettingsPageBlock;
